<template>
    <div class="LoginPage">
        <div class="header">
            <img src="../../assets/image/logo.png" alt="" />
            <div class="title">代扣管理系统</div>
        </div>

        <div class="LoginFormWrap">
            <div class="loginWarpper">
                <div class="loginTitle">欢迎登录</div>
                <div class="userForm">
                    <div class="mobile">
                        <el-input
                            v-model="userForm.mobileNumber"
                            :placeholder="mobileNumberTxt"
                            style="width: 333px"
                            @focus="getFocus(1)"
                            @blur="blurFocus(1)"
                        ></el-input>
                        <div class="mobTip">
                            <span v-if="this.userForm.mobileNumber == '' && showMobTip">未输入登录手机号</span>
                        </div>
                    </div>

                    <div class="yzm">
                        <el-input
                            v-model="userForm.code"
                            style="width: 200px"
                            :placeholder="yzmTxt"
                            @focus="getFocus(2)"
                            @blur="blurFocus(2)"
                        ></el-input>

                        <el-button size="mini" type="primary" :disabled="disabled" @click="getCode" :loading="disabled">
                            <span v-if="disabled">{{ codeTimer }}后重新获取</span>
                            <span v-else>获取验证码</span>
                        </el-button>
                    </div>
                </div>
                <div class="tip" >
                    <span v-if="!checked && showTip">请阅读并同意协议</span>
                </div>
                <div class="loginButtonBox">
                    <div class="radio">
                        <el-checkbox v-model="checked"></el-checkbox>
                        <span class="xieyi" @click="checked=true">
                            已仔细阅读并同意
                            <i @click="xieyi('./yhxy.html')">用户协议</i>
                            <!-- 、
                            <i @click="xieyi('./yszc.html')">隐私政策</i>
                            、
                            <i @click="xieyi('./fwxy.html')">产品服务协议</i> -->
                        </span>
                    </div>
                    <div class="loginButton" @click="LoginTo()">立即登录</div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import userApi from "../../api/userApi";
import axios from "axios";
export default {
    name: "LoginPage",
    data() {
        return {
            userForm: {
                mobileNumber: "",
                code: ""
            },
            smsCheck: false,
            isVerificationCode: false,
            isErrorForm: false,
            codeTimer: 60,
            codeVerification: true,
            disabled: false,
            mobileNumberTxt: "请输入登录手机号码",
            yzmTxt: "请输入验证码",

            checked: false,

            showTip:false,
            showMobTip:false,
        };
    },
    mounted() {
        document.title = "代扣系统-登录";
    },
    methods: {
        LoginTo() {
            axios
                .post(
                    "//safepayment.com.cn:18080/doLogin?mobileNumber=" +
                        this.userForm.mobileNumber +
                        "&code=" +
                        this.userForm.code
                )
                .then(res => {
                    if (res.data.status == 200) {
                        let token = res.data.data.tokenValue;
                        this.$utils.storage.set("satoken", token);
                        this.$utils.storage.set("mobileNumber", this.userForm.mobileNumber);
                        axios
                            .get("//safepayment.com.cn:18080/loginTest", {
                                headers: {
                                    satoken: token
                                }
                            })
                            .then(res => {
                                if (res.data.status == 200) {
                                    this.$router.push({ path: "/" });
                                }
                            });
                    } else {
                        this.$message({
                            type: "danger",
                            message: res.data.message
                        });
                    }
                });
        },
        getCode() {
            if (this.userForm.mobileNumber == "") {
                this.showMobTip=true
                return;
            }
            if (!this.checked) {
                this.showTip=true
                return;
            }
            axios
                .post("//safepayment.com.cn:18080/sendVerificationCode?mobileNumber=" + this.userForm.mobileNumber)
                .then(res => {});
            this.codeTimer = 59;
            this.disabled = true;
            window.clearInterval(timer);
            var timer = window.setInterval(() => {
                this.codeTimer--;
                if (this.codeTimer < 1) {
                    this.disabled = false;
                    window.clearInterval(timer);
                }
            }, 1000);
        },

        getFocus(i) {
            if (i == 1) {
                this.mobileNumberTxt = "";
            } else {
                this.yzmTxt = "";
            }
        },
        blurFocus(i) {
            if (i == 1) {
                if (this.userForm.mobileNumber == "") {
                    this.mobileNumberTxt = "请输入手机号码";
                }
            } else {
                if (this.userForm.code == "") {
                    this.yzmTxt = "请输入验证码";
                }
            }
        },
         
        xieyi(url){
            event.stopPropagation()
            let a = document.createElement("a");
            a.href = url;
            a.target = "_blank";
            a.click();
            a.remove();
        },


    }
};
</script>

<style lang="scss" scoped>
.LoginPage {
    width: 100%;
    height: 100vh;
    background: url("../../assets/image/bg1.png") no-repeat center center / 1920px;
    position: relative;
    .header {
        height: 60px;
        line-height: 60px;
        padding: 0 20px;
        border-bottom: 1px solid rgba($color: #fff, $alpha: 0.8);
        display: flex;
        align-items: center;
        img {
            width: 36px;
        }
        .title {
            font-size: 26px;
            font-weight: 700;
            margin-left: 15px;
            color: #fff;
        }
    }
    .LoginFormWrap {
        position: absolute;
        top: 42%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .loginWarpper {
            display: flex;
            flex-direction: column;
            justify-content: center;

            box-sizing: border-box;
            color: #222;
            .loginTitle {
                font-size: 18px;
                text-align: center;
                line-height: 1;
                margin-bottom: 40px;
                font-weight: 700;
                font-size: 20px;
                color: #fff;
            }
            .userForm {
                display: flex;
                flex-direction: column;
                align-items: center;
                .mobile {
                    margin-bottom: 30px;
                }
            }
            .mobTip{
                color: #e44c4c;
                margin-left: 20px;
                margin-top: 5px;
                height: 16px;
                line-height: 16px;
            }
            .tip {
                color: #e44c4c;
                margin-left: 40px;
                margin-top: 5px;
                height: 16px;
                line-height: 16px;
            }
            .loginButtonBox {
                position: relative;
                margin-top: 30px;
                .radio {
                    margin-bottom: 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    /deep/.el-checkbox__inner {
                        background: transparent;
                    }
                    /deep/.el-checkbox__label {
                        padding-left: 5px;
                    }
                    /deep/.el-checkbox__input.is-checked .el-checkbox__inner {
                        background: #409eff;
                    }
                }
                .xieyi {
                    font-size: 14px;
                    margin-left: 5px;
                    cursor: pointer;
                    i {
                        color: #409eff;
                    }
                }
                .loginButton {
                    width: 333px;
                    margin: 0 auto;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 44px;
                    background: #409eff;
                    color: #ffffff;
                    font-size: 14px;
                    line-height: 1;
                    border-radius: 4px;
                    overflow: hidden;
                    cursor: pointer;
                    transition: all 0.3s;
                    border-radius: 20px;
                    // &:hover {
                    //     opacity: 0.8;
                    // }
                    &:active {
                        box-shadow: 0px 3px 10px #666 inset;
                    }
                }
                .userTips {
                    position: absolute;
                    left: 50%;
                    top: -36px;
                    transform: translateX(-50%);
                    font-size: 14px;
                    color: #e44c4c;
                    line-height: 1;
                }
            }
            .verificationCode {
                /deep/ .el-form-item__content {
                    display: flex;
                    align-items: center;
                    a {
                        display: flex;
                        align-items: center;
                        height: 32px;
                        padding: 0 12px;
                        flex-shrink: 0;
                        font-size: 12px;
                        color: #409eff;
                        border-left: 0;
                        box-sizing: border-box;
                    }
                    span {
                        font-size: 13px;
                        color: #ccc;
                        white-space: nowrap;
                        padding: 0 12px;
                    }
                }
            }
            /deep/.el-input__inner {
                background-color: transparent;
                border: 1px solid #fff;
                color: #fff;
                transition: background-color 0.5s, border-color 1s;
                border-radius: 20px;
            }
            /deep/.el-input__inner:hover {
                border: 1px solid #409eff;
            }
            /deep/.el-input__inner:focus {
                border: 1px solid #409eff;
            }
            /deep/ input::placeholder {
                color: rgba($color: #000, $alpha: 0.4);
            }
        }

        /deep/.el-form-item__label {
            color: #222;
            font-weight: 700;
        }
    }
    .yzm {
        display: flex;
        .el-button {
            margin-left: 10px;
            border-radius: 20px;
            width: 123px;
        }
    }
}
</style>
